import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
  div,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import axios from "axios";
import {formatDate} from "../../utils/DateUtils";
import {
  CalendarToday,
  Feedback,
  GpsFixed,
  Info,
  PowerSettingsNew
} from "@material-ui/icons";
import SimpleMap from "../map/SimpleMap";
import CheckBoxGroup from "../checkBoxGroup/CheckBoxGroup";
import Section from "../Section";
import Header1 from "../headers/Header1";
import LoadingScreen from "../LoadingScreen";
import Gallery from "./Gallery";
import {CustomSnackBar} from "../CustomSnackBar";
import {reportStatus} from "../../constants/constants";
import ErrorScreen from "../ErrorScreen";
import {useSelector} from "react-redux";
import {selectError} from "../../redux/slices/error";
import {logError} from "../../utils/commonUtils";
import User from "../users/User";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: "100%",
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  title: {
    color: "#555",
    fontWeight: "bold",
  },
  section: {
    padding: theme.spacing(1),
    color: "#555",
  },
  dataContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  item: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
  text: {
    color: "#555",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  reportUpdateContainer1: {
    marginBottom: theme.spacing(2),
    display: "flex",
    direction: "row",
    // height: "30%",
  },
}));

const Report = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {id} = useParams();
  const [report, setReport] = useState(null);
  const [damageTypes, setDamageTypes] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const error = useSelector(selectError);

  useEffect(() => {
    axios
    .get("/admin/report/" + id)
    .then(res => {
      setReport(res.data);
      setLoading(false)
    })
    .catch(err => logError(err))
    setDamageTypes(JSON.parse(localStorage.getItem("damageTypes") || []));
  }, []);

  const update = () => {
    axios
    .put("/admin/report/" + id, report)
    .then(() => {
      setLoading(false);
      navigate("/reports");
    })
    .catch(err => logError(err));
  }

  const deleteReport = () => {
    axios
    .delete("/admin/report/" + id)
    .then(() => {
      setLoading(false);
      navigate("/reports");
    })
    .catch(err => logError(err));
  }

  const handleDeleteImage = (id) => {
    const updatedImages = report.images.filter(item => item !== id);
    const updatedReport = {...report, images: updatedImages}
    setReport(updatedReport);
  };

  const [edit, setEdit] = useState(false);

  const updateDamageTypes = checkedDamageTypes => {
    const updatedReport = {...report, damageTypes: checkedDamageTypes}
    setReport(updatedReport);
  }

  const handleCoordinatesChange = coordinates => {
    const updatedReport = {...report, coordinates: coordinates}
    setReport(updatedReport);
  }

  const handleChange = (event, fieldName) => {
    const value = event.target.value;
    setReport({...report, [fieldName]: value});
  };

  const handleDisabledChange = (event) => {
    const checked = event.target.checked;
    const updatedTopic = {...report, active: checked};
    setReport(updatedTopic);
  };

  const user = () => {
    if (!report.userId) {
      return <Typography>törölt felhasználó</Typography>;
    }
    if (!edit) {
      return <Typography>{report.userName}</Typography>;
    }
    return <User userId={report.userId}
                 save={() => setEdit(false)}
                 deleteUser={() => setEdit(false)}
                 extended={false}
    />
  };

  if (error) {
    return <ErrorScreen/>
  }
  return loading ? <LoadingScreen/> : (
      <div>
        <Paper className={classes.paper} elevation={0}>
          <Header1
              edit={edit}
              onEdit={() => edit ? {} : setEdit(!edit)}
              onDone={() => !edit ? {} : setEdit(!edit)}
              onSave={() => edit ? {} : update()}
              onDelete={() => edit ? {} : deleteReport()}
          />
          <Grid container>
            <Grid item xs={12} sm={4}>
              <div className={classes.section}>
                {/*++++++++++++ AKTÍV ++++++++++++*/}
                <div className={classes.dataContainer}>
                  <div className={classes.item}>
                    {isMobile ? <PowerSettingsNew className={classes.icon}/> :
                        <Typography
                            variant="subtitle2">Aktív</Typography>}
                  </div>
                  <div>
                    <Switch
                        inputProps={{'aria-label': 'controlled'}}
                        checked={report.active}
                        aria-label={"Aktív"}
                        onChange={event => handleDisabledChange(event)}
                        disabled={!edit}
                    />
                  </div>
                </div>
                {/*++++++++++++ AZONOSÍTÓ ++++++++++++*/}
                <div className={classes.dataContainer}>
                  <div className={classes.item}>
                    {isMobile ? <Info className={classes.icon}/> : <Typography
                        variant="subtitle2">Azonosító</Typography>}
                  </div>
                  <Typography
                      variant="body1">{report.id}</Typography>
                </div>
                {/*++++++++++++ DÁTUM ++++++++++++*/}
                <div className={classes.dataContainer}>
                  <div className={classes.item}>
                    {isMobile ? <CalendarToday className={classes.icon}/> :
                        <Typography
                            variant="subtitle2">Dátum</Typography>}
                  </div>
                  <Typography
                      variant="body1">{formatDate(
                      report.createdAt)}</Typography>
                </div>
                {/*++++++++++++ HELY ++++++++++++*/}
                <div className={classes.dataContainer}>
                  <div className={classes.item}>
                    {isMobile ? <GpsFixed className={classes.icon}/> :
                        <Typography
                            variant="subtitle2">Hely</Typography>}
                  </div>
                  <Typography
                      variant="body1">
                    {report.coordinates.latitude.toString().substring(0, 9) +
                        ', ' +
                        report.coordinates.longitude.toString().substring(0, 9)}
                  </Typography>
                </div>
                {/*++++++++++++ STÁTUSZ ++++++++++++*/}
                <div className={classes.dataContainer}>
                  <div className={classes.item}>
                    {isMobile ? <Feedback className={classes.icon}/> :
                        <Typography
                            variant="subtitle2">Státusz</Typography>}
                  </div>
                  {edit ?
                      <Select
                          id="status-select"
                          value={report.status}
                          Typography="Státusz"
                          onChange={event => handleChange(event, "status")}
                          variant="standard"
                          size={'small'}
                          inputProps={{
                            style: {textAlign: "right"},
                          }}
                      >
                        {reportStatus.map(item => <MenuItem
                            key={item}
                            value={item}>{item}</MenuItem>)}
                      </Select>
                      :
                      <Typography
                          variant="body1">{report.status}</Typography>
                  }
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={8}>
              <div className={classes.section}>
                <SimpleMap
                    report={report}
                    draggable={edit}
                    setCoordinates={handleCoordinatesChange}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <div className={classes.section}>
                {/*++++++++++++ KÁRESEMÉNY ++++++++++++*/}
                <div className={classes.reportUpdateContainer1}>
                  <Section
                      icon={"compost"}
                      iconText="Káresemény"
                      content={
                        <CheckBoxGroup
                            allItem={damageTypes.map(item => item.name)}
                            initiallyCheckedItems={report.damageTypes}
                            checkedItems={updateDamageTypes}
                            disabled={!edit}
                            wrapped/>
                      }
                  />
                </div>
                {/*++++++++++++ RÉSZLETES LEÍRÁS ++++++++++++*/}
                <div className={classes.reportUpdateContainer1}>
                  <Section
                      icon={"text_snippet"}
                      iconText="Részletes leírás"
                      content={edit ?
                          <TextField
                              variant="standard"
                              value={report.description}
                              onChange={event => handleChange(event,
                                  "description")}
                              multiline={true}
                              style={{width: "100%"}}
                          />
                          :
                          <Typography style={{whiteSpace: 'pre-line'}}
                              variant="body1">{report.description}</Typography>
                      }
                  />
                </div>
                {/*++++++++++++ FOTÓK ++++++++++++*/}
                <div className={classes.reportUpdateContainer1}>
                  <Section
                      icon={"collections"}
                      iconText="Fotók"
                      content={
                        <Gallery
                            images={report.images}
                            edit={edit}
                            onDelete={handleDeleteImage}
                        />
                      }
                  />
                </div>
                {/*++++++++++++ FELHASZNÁLÓ ++++++++++++*/}
                <div className={classes.reportUpdateContainer1}>
                  <Section
                      icon={"person"}
                      iconText="Felhasználó"
                      content={user()}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </Paper>
        <CustomSnackBar open={edit}/>
      </div>
  );
};

export default Report;

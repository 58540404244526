import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  damageTypes: null,
};

const damageTypes = createSlice({
  name: 'damageTypes',
  initialState,
  reducers: {
    setDamageTypes: (state, action) => {
      state.damageTypes = action.payload;
    },
  },
});

export const {setDamageTypes} = damageTypes.actions;

export const selectDamageTypes = state => state.damageTypes.damageTypes;

export default damageTypes.reducer;

import {Grid, makeStyles, ThemeProvider} from "@material-ui/core";
import {createTheme} from '@material-ui/core/styles';
import Leftbar from "./components/Leftbar";
import Navbar from "./components/Navbar";
import {BrowserRouter as Router, Route, Routes,} from "react-router-dom";
import ReportList from "./components/report/ReportList";
import Report from "./components/report/Report";
import Home from "./components/Home/Home";
import Login from "./components/authentication/Login";
import {initializeApp} from "firebase/app";
import {firebaseConfig} from "./firebaseConfig";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import React, {useEffect, useState} from "react";
import RequireAuth from "./components/RequireAuth";
import Topics from "./components/reviews/Topics";
import Topic from "./components/reviews/Topic";
import Register from "./components/authentication/Register";
import Users from "./components/users/Users";
import axios from "axios";
import LoadingScreen from "./components/LoadingScreen";
import DamageTypes from "./components/settings/DamageTypes";
import {ROLE} from "./components/authentication/roles";
import ErrorScreen from "./components/ErrorScreen";
import {useDispatch} from "react-redux";
import {setError} from "./redux/slices/error";
import {constants} from "./constants/constants";

const theme = createTheme({
  palette: {
    primary: {
      main: "#242943",
    },
    secondary: {
      main: "#50ADC9",
    },
    success: {
      main: "#107727",
    },
    info: {
      main: "#1C41D8",
    },
    warning: {
      main: "#BA7A03",
    },
    error: {
      main: "#99153A",
    },
  },
  typography: {
    body1: {
      fontSize: "90%",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  right: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mainContainer: {
    // backgroundColor: "#D9D9D9"
    backgroundColor: "#ebebeb"
  },
  container: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(10),
    },
  },
}));

const App = () => {

  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  initializeApp(firebaseConfig);
  const auth = getAuth();
  const dispatch = useDispatch()

  axios.interceptors.request.use(async config => {
    config.baseURL = constants.baseUrl;
    dispatch(setError(null));
    config.headers["Authorization"] = await getAuth().currentUser.getIdToken()
    return config
  }, (error) => {
    return Promise.reject(error)
  })

  axios.interceptors.response.use(undefined, error => {
    dispatch(setError(null))
    const statusCode = error.response ? error.response.status : null;
    switch (statusCode) {
      case 400:
        dispatch(setError("Hibás kérés."))
        break;
      case 401:
        dispatch(setError("Autentikációs hiba."))
        break;
      case 403:
        dispatch(setError("Nincs jogosultsága az oldal megtkintéséhez. Hozzáférés ADMIN felhasználótól kérhető."))
        break;
      case 404:
        dispatch(setError("A keresett oldal nem található."))
        break;
      case 500:
        dispatch(setError("Szerver oldali hiba történt."))
        break;
      default:
        dispatch(setError("Ismeretlen hiba történt."))
        break;
    }
    return Promise.reject(error);
  });

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setLoading(false);
    });
  }, [auth]);

  if (loading) {
    return <LoadingScreen/>
  }
  return (
      <ThemeProvider theme={theme}>
        <Router>
          <Navbar/>
          <Grid container className={classes.mainContainer}>
            <Grid item sm={2} xs={2}>
              <Leftbar/>
            </Grid>
            <Grid item sm={10} xs={10}>
              <div className={classes.container}>
                <Routes>
                  <Route path="/"
                         element={<RequireAuth roles={[ROLE.ADMIN,
                           ROLE.MANAGER]}><Home/></RequireAuth>}/>
                  <Route path="/reports"
                         element={<RequireAuth roles={[ROLE.ADMIN,
                           ROLE.MANAGER]}><ReportList/></RequireAuth>}/>
                  <Route path="/report/:id"
                         element={<RequireAuth roles={[ROLE.ADMIN,
                           ROLE.MANAGER]}><Report/></RequireAuth>}/>
                  <Route path="/topics"
                         element={<RequireAuth roles={[ROLE.ADMIN,
                           ROLE.MANAGER]}><Topics/></RequireAuth>}/>
                  <Route path="/topic/:id"
                         element={<RequireAuth roles={[ROLE.ADMIN,
                           ROLE.MANAGER]}><Topic/></RequireAuth>}/>
                  <Route path="/damage-types"
                         element={<RequireAuth roles={[ROLE.ADMIN,
                           ROLE.MANAGER]}><DamageTypes/></RequireAuth>}/>
                  <Route path="/users"
                         element={<RequireAuth
                             roles={[ROLE.ADMIN, ROLE.MANAGER]}><Users
                             extended={false}/></RequireAuth>}/>
                  <Route path="/extended-users"
                         element={<RequireAuth
                             roles={[ROLE.ADMIN]}><Users
                             extended={true}/></RequireAuth>}/>
                  <Route path="/login" element={<Login/>}/>
                  <Route path="/register" element={<Register/>}/>
                  <Route path="/*" element={<ErrorScreen error={404}/>}/>
                </Routes>
              </div>
            </Grid>
          </Grid>
        </Router>
      </ThemeProvider>
  )

  // return (
  //     <ThemeProvider theme={theme}>
  //       <Router>
  //         <Grid container className={classes.mainContainer}>
  //           <Grid item sm={12} xs={12}>
  //             <div className={classes.container}>
  //               <Routes>
  //                 <Route path="/"
  //                        element={<RequireAuth roles={[ROLE.ADMIN,
  //                          ROLE.MANAGER]}><Home/></RequireAuth>}/>
  //                 <Route path="/login" element={<Login/>}/>
  //                 <Route path="/register" element={<Register/>}/>
  //                 <Route path="/privacy-policy"
  //                        element={<PrivacyPolicy/>}/>
  //                 <Route path="/*" element={<ErrorScreen/>}/>
  //               </Routes>
  //             </div>
  //           </Grid>
  //         </Grid>
  //       </Router>
  //     </ThemeProvider>
  // );

};

export default App;

import React from "react";
import {makeStyles, Paper, Typography} from "@material-ui/core";
import {useSelector} from "react-redux";
import {selectError} from "../redux/slices/error";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(6),
    height: theme.spacing(48),
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    marginBottom: theme.spacing(4),
  },
}));

const ErrorScreen = () => {

  const classes = useStyles();
  const error = useSelector(selectError);

  return (
      <Paper className={classes.paper} elevation={0}>
        <div style={{}}>
          <Typography variant={"h6"} className={classes.title}>Hiba történt a
            művelet végrehajtása közben</Typography>
          <div>
            <Typography variant={"subtitle2"}>{error}</Typography>
          </div>
        </div>
      </Paper>

  )
}

export default ErrorScreen;

import {Grid, makeStyles, Paper, Typography} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {BarChart, RateReviewOutlined} from "@material-ui/icons";
import {formatDate} from "../../utils/DateUtils";
import LoadingScreen from "../LoadingScreen";
import BaseMap from "../map/BaseMap";
import {useNavigate} from "react-router-dom";
import {setTopics} from "../../redux/slices/topics";
import ErrorScreen from "../ErrorScreen";
import {selectError} from "../../redux/slices/error";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(10),
  },
  paper: {
    padding: theme.spacing(2),
    height: theme.spacing(20),
  },
  paper1: {
    padding: theme.spacing(2),
    // height: theme.spacing(47),
  },
  paperMap: {
    padding: theme.spacing(2),
    height: theme.spacing(64),
  },
  mainHeader: {
    display: "flex",
    paddingBottom: theme.spacing(2),
    justifyContent: "space-between",
  },
  title: {
    color: "#555",
    fontWeight: "bold",
  },
  icon: {
    color: "#555",
  },
  tableContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  tableRow: {
    // borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: "rgba(238,238,238,0.61)",
    display: "flex",
    justifyContent: "space-between",
    margin: theme.spacing(3),
    // color: "white"
  }

}));

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [reportList, setReportList] = useState([]);
  const [reviewList, setReviewList] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [loading, setLoading] = useState(true);
  let endpoints = [
    '/admin/report',
    '/admin/damage-type/detailed',
    '/admin/topic',
    '/admin/review/top-five',

  ];
  const error = useSelector(selectError);

  useEffect(() => {
    fetchAllData();
  }, []);

  const fetchAllData = () => {
    Promise.all(endpoints.map((endpoint) =>
        axios.get(endpoint)))
    .then(
        ([{data: reports}, {data: damageTypes}, {data: topics}, {data: reviews}]) => {
          setReportList(reports)
          setReviewList(reviews)
          setTopicList(topics)
          localStorage.setItem("damageTypes", JSON.stringify(damageTypes))
          dispatch(setTopics(topics.data))
          setLoading(false)
        }
    );
  }

  const classes = useStyles();
  const reportTable =
      <div className={classes.tableContainer}>
        {reportList
        .slice(0, 5)
        .map((item, key) => {
          return (
              <div
                  key={key}
                  className={classes.tableRow}
                  onClick={() => navigate("/report/" + item.id)}
              >
                <Typography>{item.userName ? item.userName : "törölt felhasználó"}</Typography>
                <Typography>{item.status}</Typography>
                <Typography>{formatDate(item.createdAt)}</Typography>
              </div>
          )
        })}
      </div>
  const reviewTable =
      <div className={classes.tableContainer}>
        {reviewList
        .slice(0, 5)
        .map((item, key) => {
          return (
              <div
                  key={key}
                  className={classes.tableRow}
                  onClick={() => navigate("/topic/" + item.topicId)}
              >
                <Typography>{item.userName ? item.userName : "törölt felhasználó"}</Typography>
                <Typography variant={"caption"}>{topicList.find(i => i.id === item.topicId)?.title}</Typography>
                <Typography>{formatDate(item.createdAt)}</Typography>
              </div>
          )
        })}
      </div>

  if (error) {
    return <ErrorScreen/>
  }
  return loading ? <LoadingScreen/> : (
      <div>
        <Grid container spacing={3}>
          {/*MAP*/}
          <Grid item xs={12} sm={12}>
            <Paper className={classes.paperMap} elevation={0}>
              <BaseMap reports={reportList}/>
            </Paper>
          </Grid>

          {/*LAST REPORTS*/}
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper1} elevation={0}>
              <div className={classes.mainHeader}>
                <Typography variant={"subtitle2"}>Legújabb jelentések</Typography>
                <BarChart className={classes.icon}/>
              </div>
              {reportTable}
            </Paper>
          </Grid>

          {/*LAST REVIEWS*/}
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper1} elevation={0}>
              <div className={classes.mainHeader}>
                <Typography variant={"subtitle2"}>Legújabb vélemények</Typography>
                <RateReviewOutlined className={classes.icon}/>
              </div>
              {reviewTable}
            </Paper>
          </Grid>
        </Grid>
      </div>
  );
};

export default Home;

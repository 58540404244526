import {CircularProgress} from "@material-ui/core";

const LoadingScreen = () => {

  return (
      <div style={{
        position: "fixed",
        top: "50%",
        left: "50%"
      }}>
        <CircularProgress size={40}/>
      </div>
  )
}

export default LoadingScreen;

import React, {useEffect, useState} from "react";
import {Divider, makeStyles, Paper, Tooltip} from "@material-ui/core";
import {DataGrid} from "@mui/x-data-grid";
import {Edit} from "@material-ui/icons";
import DamageType from "./DamageType";
import Header2 from "../headers/Header2";
import {CustomSnackBar} from "../CustomSnackBar";
import axios from "axios";
import LoadingScreen from "../LoadingScreen";
import CustomAlertDialog from "../CustomAlertDialog";
import ErrorScreen from "../ErrorScreen";
import {useSelector} from "react-redux";
import {selectError} from "../../redux/slices/error";

const useStyles = makeStyles((theme) => ({

  paper: {
    padding: theme.spacing(2),
    height: "100%",
  },
  table: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '.MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '&.MuiDataGrid-root': {
      border: 'none',
    },
    height: 400,
  },
  tableCell: {
    display: "flex",
    alignItems: "center"
  },
  tableHeader: {
    fontWeight: "bold",
    fontSize: "large",
  },
  tableActionEdit: {
    color: theme.palette.success.main,
    cursor: "pointer",
    marginLeft: 20,
  },
  tableActionDelete: {
    color: theme.palette.warning.main,
    cursor: "pointer",
    marginLeft: 20,
  },
}));

const DamageTypes = () => {
  const damageTypeBaseUrl = "/admin/damage-type/detailed";
  const classes = useStyles();

  const [damageTypes, setDamageTypes] = useState([]);
  const [damageType, setDamageType] = useState({});
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const emptyDamageType = {
    id: null,
    name: null,
    category: null,
    description: null
  }
  const error = useSelector(selectError);

  useEffect(() => {
    getAllDamageTypes();
  }, [damageType]);

  const getAllDamageTypes = () => {
    axios
    .get(damageTypeBaseUrl)
    .then(res => {
      setDamageTypes(res.data);
      setLoading(false)
    })
  }

  const handleDelete = (id) => {
    setDamageTypes(damageTypes.filter((item) => item.id !== id));
    setDamageType(emptyDamageType);
    setEdit(false);
  };
  const handleAddNew = () => {
    setDamageType(emptyDamageType);
    setEdit(true);
  };
  const handleEdit = (id) => {
    // force to close the edited damageType first
    if (edit) {
      return;
    }
    setEdit(true);
    setDamageType(damageTypes.find(item => item.id === id));
  };
  const handleSave = damageType => {
    setDamageTypes([...damageTypes, damageType]);
    setDamageType(emptyDamageType);
    setEdit(false);
  };
  const handleCancel = () => {
    setDamageType(emptyDamageType);
    setEdit(false);
  };

  const columns = [
    {
      field: "name",
      headerName: "Név",
      headerClassName: 'tableHeader',
      width: 200,
      renderCell: (params) => {
        return (
            <div className="tableCell">
              {params.row.name}
            </div>
        );
      },
    },
    {
      field: "category",
      headerName: "Kategória",
      headerClassName: 'tableHeader',
      width: 200,
    },
    {
      field: "description",
      headerName: "Részletes leírás",
      headerClassName: 'tableHeader',
      width: 500,
    },
    {
      field: "action",
      headerName: "Műveletek",
      headerClassName: 'tableHeader',
      width: 200,
      renderCell: (params) => {
        return (
            <>
              <Tooltip title={"szerkeszt"} placement="top" arrow>
                <Edit
                    className={classes.tableActionEdit}
                    onClick={() => handleEdit(params.row.id)}
                />
              </Tooltip>
            </>
        );
      },
    },
  ];

  const [message, setMessage] = useState(null);
  const setErrorMessage = (errorMessage) => {
    setMessage(errorMessage);
  };

  if (error) {
    return <ErrorScreen/>
  }
  return loading ? <LoadingScreen/> : (
      <div>
        <Paper className={classes.paper} elevation={0}>
          <Header2 title={"Káresemények"} onAddNew={handleAddNew}/>
          <Divider/>
          <DataGrid
              className={classes.table}
              rows={damageTypes}
              disableSelectionOnClick={true}
              columns={columns}
              pageSize={5}
              // rowsPerPageOptions={[5, 10, 20]}
              // checkboxSelection
              onRowClick={(rowInfo) => handleEdit(rowInfo.row.id)}
              components={{
                // Toolbar: GridToolbar,
              }}
          />
        </Paper>
        <CustomSnackBar open={edit && !message}/>
        <DamageType
            edit={edit}
            damageTypeToEdit={damageType}
            save={handleSave}
            cancel={handleCancel}
            deleteDamageType={handleDelete}
            errorMessage={setErrorMessage}/>
        <CustomAlertDialog
            open={message}
            title={"Hiba"}
            message={message}
            handleAccept={() => setMessage(null)}
        />
      </div>
  )
}

export default DamageTypes;

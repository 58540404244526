import {
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import Icon from '@material-ui/core/Icon'

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  iconText: {},
  icon: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
}));

const SectionItem = ({icon, iconText}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (

      <div className={classes.item}>
        {
          isMobile ?
              <Icon className={classes.icon}>{icon}</Icon>
              : <Typography variant="subtitle2">{iconText}</Typography>
        }
      </div>
  )
}

export default SectionItem;

import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";

export const CustomSnackBar = ({open}) => {
  return <Snackbar
      open={open}
      anchorOrigin={{vertical: "top", horizontal: "right"}}
  >
    <Alert severity="warning">
      Szerkesztés folyamatban...
    </Alert>
  </Snackbar>
}

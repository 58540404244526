import React, {useEffect} from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import {makeStyles, useTheme} from "@material-ui/core";
import {resolveMarkerColor} from "../../utils/commonUtils";

const useStyles = makeStyles((theme) => ({
  map: {
    height: 180,
    width: "100%",
  }
}))

const SimpleMap = ({report, draggable, setCoordinates}) => {
  mapboxgl.accessToken = "pk.eyJ1Ijoic3VubnlzeXMiLCJhIjoiY2t6a3owb2J1MjlpdDJ1bnlsZm5ieDdicCJ9.CBnKmrbAM7mR4gfKtCaLLQ";
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {

    //MAP
    const map = new mapboxgl.Map({
      container: "mapContainer",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [report.coordinates.longitude, report.coordinates.latitude],
      zoom: 14,
    });

    //CONTROL
    const geolocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true,
    });
    map.addControl(geolocate, "top-right")

    //MARKER
    const marker = new mapboxgl.Marker({
      draggable: draggable,
      color: resolveMarkerColor(report, draggable, theme),
    })
    .setLngLat([report.coordinates.longitude, report.coordinates.latitude])
    .addTo(map)

    //DRAG_EVENT
    marker.on('dragend', () => onDragEnd(marker));

  }, [draggable]);

  function onDragEnd(marker) {
    const lngLat = marker.getLngLat();
    const coordinates = {latitude: lngLat.lat, longitude: lngLat.lng};
    setCoordinates(coordinates);
  }

  return <div id="mapContainer" className={classes.map}/>;
};

export default SimpleMap;

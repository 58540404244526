import {makeStyles, Paper, Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    height: 300,
  }
}));

export const AccessDenied = ({roles, userRole}) => {
  const roleList = roles.map(item => <li key={item}>{item}</li>)
  const classes = useStyles();

  return (
      <div className="access-denied">
        <Paper  className={classes.paper} elevation={0}>
          <Typography variant={"h6"}>Hozzáférés megtagadva</Typography>
          <br/>
          <Typography>Az oldal csak az alábbi jogosultsággal
            érhető el: {roleList}</Typography>
          <br/>
          <Typography>Aktuális jogosultás: {userRole}</Typography>
          <br/>
          <Typography>Hozzáférés ADMIN jogosultsággal rendelkező felhasználótól igényelhető.</Typography>
        </Paper>
      </div>
  );
};

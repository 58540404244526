import React, {useEffect} from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import {makeStyles, useTheme} from "@material-ui/core";
import {useNavigate} from "react-router-dom";
import {resolveMarkerColor} from "../../utils/commonUtils";

const useStyles = makeStyles((theme) => ({
  map: {
    width: "100%",
    height: "100%"
  }
}))

const BaseMap = ({reports}) => {
  mapboxgl.accessToken = "pk.eyJ1Ijoic3VubnlzeXMiLCJhIjoiY2t6a3owb2J1MjlpdDJ1bnlsZm5ieDdicCJ9.CBnKmrbAM7mR4gfKtCaLLQ";
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();

  //MAP
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: "mapContainer",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [17.44635125112029, 47.862590922404046],
      zoom: 10,
    });

    //CONTROL
    const geolocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true,
      showUserHeading: true
    });
    map.addControl(geolocate, "top-right")

    //NAVIGATION_CONTROL
    const nav = new mapboxgl.NavigationControl();
    map.addControl(nav, 'top-left');

    //MARKERS
    reports.map(item => {
          const marker = new mapboxgl.Marker({
            color: resolveMarkerColor(item, false, theme)
          });
          marker.setLngLat([item.coordinates.longitude, item.coordinates.latitude])
          marker.addTo(map)
          marker.getElement().addEventListener('click', () => {
            navigate("/report/" + item.id);
          });
        }
    )
  }, []);

  return <div id="mapContainer" className={classes.map}/>;
};

export default BaseMap;

import {
  alpha,
  AppBar,
  makeStyles,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {ExitToApp} from "@material-ui/icons";
import {getAuth} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import {setUser} from "../redux/slices/user";
import {useDispatch} from "react-redux";

const useStyles = makeStyles((theme) => ({
  appBar: {
    // height: theme.spacing(8),
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    // backgroundColor: '#242943',
  },
  logoLg: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  logoSm: {
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  search: {
    display: "flex",
    alignItems: "center",
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    borderRadius: theme.shape.borderRadius,
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      display: (props) => (props.open ? "flex" : "none"),
      width: "70%",
    },
  },
  input: {
    color: "white",
    marginLeft: theme.spacing(1),
  },
  cancel: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  searchButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  icons: {
    alignItems: "center",
    display: (props) => (props.open ? "none" : "flex"),
  },
  badge: {
    marginRight: theme.spacing(2),
  },
  rightIcons: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  iconContainer: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  text: {
    marginRight: theme.spacing(1),
    fontSize: "90%",
    fontWeight: 400,
  },
}));

const Navbar = () => {

  const navigation = useNavigate();
  const auth = getAuth();
  const user = auth.currentUser;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  const handleLogin = () => {
    window.location.reload(false);
  }

  const handleLogout = () => {
    getAuth().signOut()
    .then(() => {
      console.log(user.displayName + " signed out");
      dispatch(setUser(null));
    })
    .then(() => navigation("/"))
    .catch((error) => {
      console.log("error", error);
    });
  }
  const classes = useStyles();
  return (
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h5" className={classes.logoLg}>
            SzigetközAdmin
          </Typography>
          <Typography variant="h6" className={classes.logoSm}>
            Admin
          </Typography>
          {user ?
              <div className={classes.rightIcons}>
                <Typography
                    className={classes.text}>{user.email}</Typography>
                <div className={classes.iconContainer}
                     onClick={handleLogout}>
                  <ExitToApp className={classes.icon}/>
                  {isMobile ? null :
                      <Typography
                          className={classes.text}>Kijelentkezés</Typography>}
                </div>
              </div>
          :
              <div className={classes.rightIcons}>
                <div className={classes.iconContainer}
                     onClick={handleLogin}>
                  <ExitToApp className={classes.icon}/>
                  {isMobile ? null :
                      <Typography
                          className={classes.text}>Bejelentkezés</Typography>}
                </div>
              </div>}
        </Toolbar>
      </AppBar>
  );
};

export default Navbar;

import {useEffect, useState} from "react";
import {
  div,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography
} from "@material-ui/core";
import axios from "axios";
import Header3 from "../headers/Header3";
import {ROLE} from "../authentication/roles";
import {useSelector} from "react-redux";
import {selectUser} from "../../redux/slices/user";
import {
  fieldIsNullOrEmpty,
  logError,
  mandatoryFieldHelperText
} from "../../utils/commonUtils";
import LoadingScreen from "../LoadingScreen";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    height: "100%",
  },
  textField: {
    width: "95%",
  },
  switch: {
    textAlign: "center"
  },
  fieldContainer: {
    marginBottom: theme.spacing(2),
  },
  tableHeader: {
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(1),
    }
  }

}));

const User = ({
  extended,
  userId,
  save,
  cancel,
  deleteUser,
  errorMessage
}) => {
  const loggedInUser = useSelector(selectUser);
  const editLoggedInUser = userId !== null && loggedInUser.id
      === userId;
  const userBaseUrl = extended ? "/admin/user/extended-users/"
      : "/admin/user/users/";
  const userIdeUrl = extended ? "/admin/user/extended-users/"
      : "/admin/user/";
  const classes = useStyles();

  const [user, setUser] = useState(userId);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userId) {
      getUserById()
    }
  }, [])

  const getUserById = () => {
    setLoading(true);
    axios
    .get(userIdeUrl + userId)
    .then(res => {
      setUser(res.data);
      setLoading(false)
    })
    .catch(err => logError(err))
  }

  const handleRoleChange = (event) => {
    const newRole = event.target.value;
    const updateUser = {...user, role: newRole}
    setUser(updateUser);
  };

  const handleDisabledChange = (event) => {
    const checked = event.target.checked;
    const updatedUser = {...user, active: checked};
    setUser(updatedUser);
  };

  const handleChange = (event, fieldName) => {
    const value = event.target.value;
    setUser({...user, [fieldName]: value});
  };

  const onSave = (user) => {
    save(user)
  }
  const onCancel = () => {
    cancel()
  }
  const onDelete = () => {
    deleteUser()
  }
  const onError = (error) => {
    errorMessage(error)
  }

  const handleSave = () => {
    if (fieldIsNullOrEmpty(user.name)
        || fieldIsNullOrEmpty(user.role)) {
      onError("Hiányzó adat")
      return;
    }
    axios
    .put(userBaseUrl + user.id, user)
    .then(() => onSave(user))
  };

  const handleDelete = () => {
    axios
    .delete(userBaseUrl + user.id)
    .then(() => onDelete(user.id))
  };

  if (loading) {
    return <LoadingScreen/>
  }

  return (
      user &&
      <Paper className={classes.paper} elevation={0}>
        <Header3
            onCancel={cancel ? onCancel : null}
            onSave={handleSave}
            onDelete={editLoggedInUser ? null : handleDelete}
        />
        <Grid container>
          <Grid item sm={4} xs={12} className={classes.fieldContainer}>
            <Typography variant={"subtitle2"}
                        className={classes.tableHeader}>Felhasználó</Typography>
            <TextField
                className={classes.textField}
                variant="standard"
                value={user.name}
                error={fieldIsNullOrEmpty(user.name)}
                helperText={mandatoryFieldHelperText(user.name)}
                placeholder={"Név"}
                onChange={event => handleChange(event, "name")}
                multiline={true}
            />
          </Grid>
          <Grid item sm={4} xs={12} className={classes.fieldContainer}>
            <Typography variant={"subtitle2"}
                        className={classes.tableHeader}>Email</Typography>
            <Typography>{user.email}</Typography>
          </Grid>
          <Grid item sm={2} xs={12} className={classes.fieldContainer}>
            <Typography variant={"subtitle2"}
                        className={classes.tableHeader}>Jogosultság</Typography>
            <Select
                id="role-select"
                value={user.role}
                Typography="Jogosultság"
                onChange={handleRoleChange}
                variant="standard"
                size={'small'}
                className={classes.textField}
                disabled={!extended || editLoggedInUser}
            >
              {Object.entries(ROLE).map(([key, value]) => <MenuItem
                  key={key}
                  value={key}>
                {value}
              </MenuItem>)}
            </Select>
          </Grid>
          <Grid item sm={2} xs={12} className={classes.fieldContainer}>
            <Typography variant={"subtitle2"}
                        className={classes.tableHeader}>Aktív</Typography>
            <div className={classes.switch}>
              <Switch
                  inputProps={{'aria-label': 'controlled'}}
                  checked={user.active}
                  aria-label={"Aktív"}
                  onChange={event => handleDisabledChange(event)}
                  disabled={editLoggedInUser}
              />
            </div>
          </Grid>
        </Grid>
      </Paper>
  )
}

export default User;

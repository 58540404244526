import {Navigate, useLocation} from "react-router-dom";
import {getAuth} from "firebase/auth";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/slices/user";
import {AccessDenied} from "./authentication/AccessDenied";

const RequireAuth = ({children, roles}) => {

  let location = useLocation();

  const auth = getAuth();
  const firebaseUser = auth.currentUser;
  const isAuthenticated = firebaseUser != null;
  const userWithRole = useSelector(selectUser);

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{from: location}} replace/>;
  }
  if (!userWithRole) {
    auth.signOut().then(() => console.log(firebaseUser.displayName + " signed out"));
    return <Navigate to="/login" state={{from: location}} replace/>;
  }
  const userHasRequiredRole = roles.includes(userWithRole.role);
  if (!userHasRequiredRole) {
    return <AccessDenied roles={roles} userRole={userWithRole.role}/>;
  }

  return children;
}

export default RequireAuth;

export const constants = {
  baseUrl: "https://api.szigetkozapp.hu",

};

export const roles =
    [
      {
        id: 0,
        name: "ADMIN",
        translated: "admin"
      },
      {
        id: 1,
        name: "MANAGER",
        translated: "menedzser"
      },
      {
        id: 2,
        name: "USER",
        translated: "felhasználó"
      },
    ]

export const reportStatus = ["beküldve", "befogadva", "elhárítás", "elhárítva"];


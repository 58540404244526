import {
  div,
  IconButton,
  makeStyles,
  Tooltip,
  Typography
} from "@material-ui/core";
import {AddCircle} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({

  mainHeader: {
    display: "flex",
    paddingBottom: theme.spacing(2),
    justifyContent: "space-between",
    // marginRight: theme.spacing(2),
  },
  title: {
    color: "#555",
    fontWeight: "bold",
  },
  largeIcon: {
    width: 28,
    height: 28,
  },
}));

const Header2 = ({title, onAddNew}) => {
  const classes = useStyles();
  return (
      <div className={classes.mainHeader}>
        <Typography
            variant={"body1"}
            color={"primary"}
            className={classes.title}
        >{title}</Typography>
        <Tooltip title={"új"} placement="top" arrow>
          <IconButton
              color={"secondary"}
              onClick={onAddNew}
          >
            <AddCircle className={classes.largeIcon}/>
          </IconButton>
        </Tooltip>
      </div>
  )
}
export default Header2;

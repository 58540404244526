import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  report: null,
};

const reports = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setReports: (state, action) => {
      state.reports = action.payload;
    },
  },
});

export const {setReports} = reports.actions;

export const selectReports = state => state.reports.reports;

export default reports.reducer;

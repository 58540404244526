import React, {useEffect, useState} from "react";
import {
  Divider,
  makeStyles,
  Paper,
  Switch,
  Tooltip,
  Typography
} from "@material-ui/core";
import {DataGrid} from "@mui/x-data-grid";
import {Edit} from "@material-ui/icons";
import axios from "axios";
import LoadingScreen from "../LoadingScreen";
import User from "./User";
import {CustomSnackBar} from "../CustomSnackBar";
import {useSelector} from "react-redux";
import {selectUser} from "../../redux/slices/user";
import {ROLE} from "../authentication/roles";
import CustomAlertDialog from "../CustomAlertDialog";
import {selectError} from "../../redux/slices/error";
import ErrorScreen from "../ErrorScreen";

const useStyles = makeStyles((theme) => ({

  paper: {
    padding: theme.spacing(2),
    height: "100%",
  },
  mainHeader: {
    display: "flex",
    paddingBottom: theme.spacing(6),
    justifyContent: "space-between",
    marginRight: theme.spacing(2),
  },
  title: {
    color: "#555",
    fontWeight: "bold",
  },
  table: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '.MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '&.MuiDataGrid-root': {
      border: 'none',
    },
    height: 400,
  },
  tableCell: {
    display: "flex",
    alignItems: "center"
  },
  tableHeader: {
    fontWeight: "bold",
    fontSize: "large",
  },
  tableActionEdit: {
    color: theme.palette.success.main,
    cursor: "pointer",
    marginLeft: 20,
  },
  tableActionDelete: {
    color: theme.palette.warning.main,
    cursor: "pointer",
    marginLeft: 20,
  },
}));

const Users = ({extended}) => {
  const loggedInUser = useSelector(selectUser);
  const isAdmin = loggedInUser.role === ROLE.ADMIN;
  const userBaseUrl = extended ? "/admin/user/extended-users/"
      : "/admin/user/users/";
  const classes = useStyles();

  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const error = useSelector(selectError);

  useEffect(() => {
    getAllUsers();
  }, [user, extended]);

  const getAllUsers = () => {
    axios
    .get(userBaseUrl)
    .then(res => {
      setUsers(res.data);
      setLoading(false)
    })
  }

  const handleDelete = (id) => {
    setUsers(users.filter((item) => item.id !== id));
    setUser(null)
  };
  const handleEdit = (id) => {
    // force to close the edited user first
    if (user) {
      return;
    }
    setUser(users.find(item => item.id === id));
  };
  const handleSave = user => {
    setUsers([...users, user]);
    setUser(null)
  };
  const handleCancel = () => {
    setUser(null);
  };

  const columns = [
    {
      field: "name",
      headerName: "Felhasználó",
      headerClassName: 'tableHeader',
      width: 200,
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: 'tableHeader',
      width: 250,
    },
    {
      field: "role",
      headerName: "Jogosultság",
      headerClassName: 'tableHeader',
      width: 150,
    },
    {
      field: "active",
      headerName: "Aktív",
      headerClassName: 'tableHeader',
      width: 120,
      renderCell: (params) => {
        return <Switch
            disabled={true}
            checked={params.row.active}
            inputProps={{'aria-label': 'controlled'}}
        />
      },
    },
    {
      field: "action",
      headerName: "Műveletek",
      headerClassName: 'tableHeader',
      width: 200,
      renderCell: (params) => {
        return (
            <>
              <Tooltip title={"szerkeszt"} placement="top" arrow>
                <Edit
                    className={classes.tableActionEdit}
                    onClick={() => handleEdit(params.row.id)}
                />
              </Tooltip>
            </>
        );
      },
    },
  ];

  const [message, setMessage] = useState(null);
  const setErrorMessage = (errorMessage) => {
    setMessage(errorMessage);
  };

  if (error) {
    return <ErrorScreen/>
  }
  return loading ? <LoadingScreen/> : (
      <div>
        <Paper className={classes.paper} elevation={0}>
          <div className={classes.mainHeader}>
            <Typography variant={"body1"}
                        color={"primary"}
                        className={classes.title}
            >Felhasználók</Typography>
          </div>
          <Divider/>
          <DataGrid
              className={classes.table}
              rows={users}
              disableSelectionOnClick={true}
              columns={columns}
              pageSize={5}
              // rowsPerPageOptions={[5, 10, 20]}
              // checkboxSelection
              onRowClick={(rowInfo) => handleEdit(rowInfo.row.id)}
              components={{
                // Toolbar: GridToolbar,
              }}
          />
        </Paper>
        <CustomSnackBar open={user !== null && !message}/>
        {user &&
            <User
                extended={isAdmin}
                userId={user.id}
                save={handleSave}
                cancel={handleCancel}
                deleteUser={handleDelete}
                errorMessage={setErrorMessage}
            />
        }
        <CustomAlertDialog
            open={message}
            title={"Hiba"}
            message={message}
            handleAccept={() => setMessage(null)}
        />
      </div>
  )
}

export default Users;

import React, {useEffect, useState} from "react";
import {
  Divider,
  makeStyles,
  Paper,
  Switch,
  Typography
} from "@material-ui/core";
import {DataGrid} from "@mui/x-data-grid";
import {CommentOutlined, Edit} from "@material-ui/icons";
import {Rating} from "@material-ui/lab";
import {useNavigate} from "react-router-dom";
import Section from "../Section";
import Header2 from "../headers/Header2";
import LoadingScreen from "../LoadingScreen";
import ErrorScreen from "../ErrorScreen";
import {useSelector} from "react-redux";
import {selectError} from "../../redux/slices/error";
import axios from "axios";
import {logError} from "../../utils/commonUtils";

const useStyles = makeStyles((theme) => ({

  paper: {
    padding: theme.spacing(2),
    height: "100%",
  },
  mainHeader: {
    display: "flex",
    paddingBottom: theme.spacing(2),
    justifyContent: "space-between",
    marginRight: theme.spacing(2),
  },
  title: {
    color: "#555",
    fontWeight: "bold",
  },
  table: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '.MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '&.MuiDataGrid-root': {
      border: 'none',
    },
    height: 500,
  },
  tableCell: {
    display: "flex",
    alignItems: "center"
  },
  tableHeader: {
    fontWeight: "bold",
    fontSize: "large",
  },
  tableActionEdit: {
    color: theme.palette.success.main,
    cursor: "pointer",
    marginLeft: 20,
  },
  tableActionDelete: {
    color: theme.palette.warning.main,
    cursor: "pointer",
    marginLeft: 20,
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: 18,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
}));

const Topics = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(true);
  const error = useSelector(selectError);

  useEffect(() => {
    axios
    .get("/admin/topic")
    .then(res => {
      setLoading(false);
      setTopics(res.data);
    })
    .catch(err => logError(err))
  }, []);

  const handleAddNew = () => {
    navigate("/topic/new");
  };
  const handleEdit = (id) => {
    navigate("/topic/" + id);
  };

  const columns = [
    {
      field: "title",
      headerName: "Cím",
      headerClassName: 'tableHeader',
      width: 200,
    },
    {
      field: "summary",
      headerName: "Rövid leírás",
      headerClassName: 'tableHeader',
      width: 200,
    },
    {
      field: "description",
      headerName: "Részletes leírás",
      headerClassName: 'tableHeader',
      width: 300,
    },
    {
      field: "rating",
      headerName: "Értékelés",
      headerClassName: 'tableHeader',
      width: 180,
      renderCell: (params) => {
        return (
            <Rating
                size="small"
                precision={0.5}
                readOnly
                value={params.row.rating}
            />
        );
      },
    },
    {
      field: "comments",
      headerName: "Vélemények",
      headerClassName: 'tableHeader',
      width: 180,
      renderCell: (params) => {
        return (
            <Section
                icon={<CommentOutlined className={classes.icon}/>}
                content={<Typography
                    style={{fontWeight: "bold"}}>{params.row.reviews.length}</Typography>}
            />
        );
      },
    },
    {
      field: "active",
      headerName: "Aktív",
      headerClassName: 'tableHeader',
      width: 120,
      renderCell: (params) => {
        return <Switch
            disabled={true}
            checked={params.row.active}
            inputProps={{'aria-label': 'controlled'}}
        />
      },
    },
    {
      field: "action",
      headerName: "Műveletek",
      headerClassName: 'tableHeader',
      width: 200,
      renderCell: (params) => {
        return (
            <>
              <Edit
                  className={classes.tableActionEdit}
                  onClick={() => handleEdit(params.row.id)}
              />
            </>
        );
      },
    },
  ];

  if (error) {
    return <ErrorScreen/>
  }
  return (
      loading ? <LoadingScreen/> : <div>
        <Paper className={classes.paper} elevation={0}>
          <Header2 title={"Témák"} onAddNew={handleAddNew}/>
          <Divider/>
          <DataGrid
              className={classes.table}
              rows={topics}
              disableSelectionOnClick={true}
              columns={columns}
              pageSize={5}
              // rowsPerPageOptions={[5, 10, 20]}
              // checkboxSelection
              onRowClick={(rowInfo) => handleEdit(rowInfo.row.id)}
              components={{
                // Toolbar: GridToolbar,
              }}
          />
        </Paper>
      </div>
  )
}

export default Topics;

import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  error: null,
};

const error = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {setError} = error.actions;

export const selectError = state => state.error.error;

export default error.reducer;

import {
  ImageList,
  ImageListItem,
  makeStyles,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import {Delete, Fullscreen} from "@material-ui/icons";
import ImgsViewer from "react-images-viewer";
import {useEffect, useState} from "react";
import axios from "axios";
import {constants} from "../../constants/constants";

const useStyles = makeStyles((theme) => ({
  imageList: {
    justifyContent: 'flex-start',
  },
  imageIcon: {
    fontSize: 30,
    position: 'absolute',
    top: 5,
    right: 5,
    zIndex: 1,
    color: 'white'
  },
}));

const Gallery = ({images, edit, onDelete}) => {

  // TODO get images with axios interception
  const getImages = () => {
    images.map(item => {
      axios.get("/files/" + item)
      .then(res => {
        setImageList([...imageList, res.data])
      })
    })
  };

  useEffect(() => {
    createImageList(images);
  }, []);

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const cols = isMobile ? 1 : 5;
  const gap = isMobile ? 2 : 8;
  const [imageListOpen, setImageListOpen] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [currImg, setCurrImg] = useState(null);
  const createImageList = () => {
    const imageList = images.map(item =>
        ({"src": constants.baseUrl + "/files/" + item})
    )
    setImageList(imageList);
  }
  const gotoPrev = () => {
    setCurrImg(currImg - 1);
  }
  const gotoNext = () => {
    setCurrImg(currImg + 1);
  }
  const gotoImg = (index) => {
    setCurrImg(index);
  }
  const openImgsViewer = (index) => {
    setCurrImg(index);
    setImageListOpen(true)
  }

  const closeImgsViewer = () => {
    setCurrImg(0);
    setImageListOpen(false)
  }

  return (
      <div>
        <ImageList variant="masonry" cols={cols} gap={gap}
                   className={classes.imageList}>
          {images.map((item) => (
              <ImageListItem key={item}>
                <img
                    src={constants.baseUrl + `${"/files/"
                    + item}?w=248&fit=crop&auto=format`}
                    // srcSet={`${"/files/"
                    // + item}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={item}
                    loading="lazy"
                    onClick={() => openImgsViewer(images.indexOf(item))}
                />
                {edit ?
                    <Delete
                        className={classes.imageIcon}
                        onClick={() => onDelete(item)}
                    />
                    :
                    <Fullscreen
                        className={classes.imageIcon}
                        onClick={() => openImgsViewer(images.indexOf(item))}/>
                }
              </ImageListItem>
          ))}
        </ImageList>
        <ImgsViewer
            currImg={currImg}
            showImgCount={false}
            imgs={imageList}
            isOpen={imageListOpen}
            onClickImg={closeImgsViewer}
            onClickNext={gotoNext}
            onClickPrev={gotoPrev}
            onClickThumbnail={gotoImg}
            onClose={() => closeImgsViewer()}
        />
      </div>
  )
}

export default Gallery;

import {
  div,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {DeleteForever, DoneAll, Edit, Save} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({

  mainHeader: {
    display: "flex",
    paddingBottom: theme.spacing(1),
    justifyContent: "space-between",
  },
  title: {
    color: "#555",
    fontWeight: "bold",
  },
  icons: {
    display: "flex",
  },
  largeIcon: {
    width: 28,
    height: 28,
  },

}));

const Header1 = ({edit, onEdit, onDone, onSave, onDelete}) => {
  const classes = useStyles();
  return (
      <div className={classes.mainHeader}>
        <Typography variant={"body1"} color={"primary"}
                    className={classes.title}>Műveletek</Typography>
        <div className={classes.icons}>
          {edit ?
              <Tooltip title={"kész"} placement="top" arrow>
                <IconButton color={"secondary"}
                            onClick={onDone}>
                  <DoneAll className={classes.largeIcon}/>
                </IconButton>
              </Tooltip>
              :
              <Tooltip title={"szerkeszt"} placement="top" arrow>
                <IconButton color={"secondary"}
                            onClick={onEdit}>
                  <Edit className={classes.largeIcon}/>
                </IconButton>
              </Tooltip>
          }
          <Tooltip title={"ment"} placement="top" arrow>
            <IconButton color={edit ? "default" : "secondary"}
                        onClick={onSave}>
              <Save className={classes.largeIcon}/>
            </IconButton>
          </Tooltip>
          {onDelete &&
              <Tooltip title={"töröl"} placement="top" arrow>
                <IconButton color={edit ? "default" : "secondary"}
                            onClick={onDelete}>
                  <DeleteForever className={classes.largeIcon}/>
                </IconButton>
              </Tooltip>
          }
        </div>
      </div>
  )
}
export default Header1;

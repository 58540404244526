import {Container, makeStyles, Typography} from "@material-ui/core";
import {Eco, Home, RateReview, SupervisorAccount,} from "@material-ui/icons";
import Icon from '@material-ui/core/Icon'
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    color: "white",
    paddingTop: theme.spacing(10),
    backgroundColor: "#1D2136",
    position: "sticky",
    top: 0,
    [theme.breakpoints.up("sm")]: {
      backgroundColor: "#3F4462",
      color: "#555",
      paddingLeft: theme.spacing(5),
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  item: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(3),
      cursor: "pointer",
    },
  },
  icon: {
    color: "white",
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      fontSize: "18px",
    },
  },
  text: {
    color: "white",
    fontSize: "92%",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  subText: {
    margin: theme.spacing(2),
    marginLeft: 0,
    color: "#a1a1a1",
    fontWeight: 400,
    fontSize: "small",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const Leftbar = () => {
  const classes = useStyles();

  return (
      <Container className={classes.container}>
        <Typography className={classes.subText}
                    variant={"body2"}>Oldalak</Typography>
        <Link to="/" className={classes.link}>
          <div className={classes.item}>
            <Home className={classes.icon}/>
            <Typography className={classes.text}>Kezdő oldal</Typography>
          </div>
        </Link>
        <Link to="/reports" className={classes.link}>
          <div className={classes.item}>
            <Icon className={classes.icon}>analytics</Icon>
            <Typography className={classes.text}>Káresemény
              jelentések</Typography>
          </div>
        </Link>
        <Link to="/topics" className={classes.link}>
          <div className={classes.item}>
            <RateReview className={classes.icon}/>
            <Typography className={classes.text}>Témák / Vélemények</Typography>
          </div>
        </Link>
        <Typography className={classes.subText}
                    variant={"body2"}>Manager</Typography>
        <Link to="/users" className={classes.link}>
          <div className={classes.item}>
            <SupervisorAccount className={classes.icon}/>
            <Typography className={classes.text}>Felhasználók</Typography>
          </div>
        </Link>
        <Link to="/damage-types" className={classes.link}>
          <div className={classes.item}>
            <Eco className={classes.icon}/>
            <Typography className={classes.text}>Káresemények</Typography>
          </div>
        </Link>
        <Typography className={classes.subText}
                    variant={"body2"}>Admin</Typography>
        <Link to="/extended-users" className={classes.link}>
          <div className={classes.item}>
            <SupervisorAccount className={classes.icon}/>
            <Typography className={classes.text}>Felhasználók</Typography>
          </div>
        </Link>
      </Container>
  );
};

export default Leftbar;

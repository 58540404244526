import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
  Divider,
  makeStyles,
  Paper,
  Switch,
  Typography
} from "@material-ui/core";
import {DataGrid} from "@mui/x-data-grid";
import {Edit} from "@material-ui/icons";
import {formatDate} from "../../utils/DateUtils";
import CheckBoxGroup from "../checkBoxGroup/CheckBoxGroup";
import LoadingScreen from "../LoadingScreen";
import {useSelector} from "react-redux";
import {selectError} from "../../redux/slices/error";
import ErrorScreen from "../ErrorScreen";
import axios from "axios";
import {logError} from "../../utils/commonUtils";

const useStyles = makeStyles((theme) => ({

  paper: {
    padding: theme.spacing(2),
    height: "100%",
  },
  mainHeader: {
    paddingBottom: theme.spacing(6),
  },
  title: {
    color: "#555",
    fontWeight: "bold",
  },
  table: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '.MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '&.MuiDataGrid-root': {
      border: 'none',
    },
    height: 500
  },
  tableCell: {
    display: "flex",
    alignItems: "center"
  },
  tableHeader: {
    fontWeight: "bold",
    fontSize: "large",
  },
  tableActionEdit: {
    color: theme.palette.success.main,
    cursor: "pointer",
    marginLeft: 20,
  },
  tableActionDelete: {
    color: theme.palette.warning.main,
    cursor: "pointer",
    marginLeft: 20,
  },
}));

const ReportList = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [reports, setReports] = useState([]);
  // const [reports, setReports] = useState(createDummyReports(1000));
  const [loading, setLoading] = useState(true);
  const error = useSelector(selectError);

  useEffect(() => {
    axios
    .get("/admin/report")
    .then(res => {
      setReports(res.data);
      setLoading(false);
    })
    .catch(err => logError(err))
  }, []);

  const handleDelete = (id) => {
    setReports(reports.filter((item) => item.id !== id));
  };
  const handleEdit = (id) => {
    navigate("/report/" + id);
  };

  const columns = [
    {
      field: "userName",
      headerName: "Felhasználó",
      headerClassName: 'tableHeader',
      width: 200,
      renderCell: (params) => {
        return params.row.userId ? params.row.userName : "törölt felhasználó";
      },
    },
    {
      field: "description",
      headerName: "Részletes leírás",
      headerClassName: 'tableHeader',
      width: 300
    },
    {
      field: "damageTypes",
      headerName: "Káresemények",
      headerClassName: 'tableHeader',
      width: 250,
      renderCell: (params) => {
        return (
            <div className={classes.tableCell}>
              <CheckBoxGroup
                  allItem={params.row.damageTypes}
                  initiallyCheckedItems={params.row.damageTypes}
                  disabled={true}/>
            </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Státusz",
      headerClassName: 'tableHeader',
      width: 200,
    },
    {
      field: "createdAt",
      headerName: "Dátum",
      headerClassName: 'tableHeader',
      width: 200,
      renderCell: (params) => {
        return formatDate(params.row.createdAt);
      },
    },
    {
      field: "active",
      headerName: "Aktív",
      headerClassName: 'tableHeader',
      width: 120,
      renderCell: (params) => {
        return <Switch
            disabled={true}
            checked={params.row.active}
            inputProps={{'aria-label': 'controlled'}}
        />
      },
    },
    {
      field: "action",
      headerName: "Műveletek",
      headerClassName: 'tableHeader',
      width: 200,
      renderCell: (params) => {
        return (
            <>
              <Edit
                  className={classes.tableActionEdit}
                  onClick={() => handleEdit(params.row.id)}
                  disbaled={true}
              />
              {/*<DeleteOutline*/}
              {/*    className={classes.tableActionDelete}*/}
              {/*    onClick={() => handleDelete(params.row.id)}*/}
              {/*/>*/}
            </>
        );
      },
    },
  ];

  if (error) {
    return <ErrorScreen/>
  }
  return loading ? <LoadingScreen/> :
      <Paper className={classes.paper} elevation={0}>
        <div className={classes.mainHeader}>
          <Typography variant={"body1"} color={"primary"}
                      className={classes.title}>Káresemény
            jelentések</Typography>
        </div>
        <Divider/>
        <DataGrid
            className={classes.table}
            rows={reports}
            disableSelectionOnClick={true}
            columns={columns}
            pageSize={5}
            // rowsPerPageOptions={[5, 10, 20]}
            // checkboxSelection
            onRowClick={(rowInfo) => handleEdit(rowInfo.row.id)}
            components={{
              // Toolbar: GridToolbar,
            }}
        />
      </Paper>;
}

export default ReportList;

import {
  div,
  IconButton,
  makeStyles,
  Tooltip,
  Typography
} from "@material-ui/core";
import {Cancel, DeleteForever, Save} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({

  mainHeader: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
    paddingBottom: theme.spacing(2),
    justifyContent: "space-between",
  },
  title: {
    color: "#555",
    fontWeight: "bold",
  },
  icons: {},
  largeIcon: {
    width: 28,
    height: 28,
  },
}));

const Header3 = ({onCancel, onSave, onDelete}) => {
  const classes = useStyles();
  return (
      <div className={classes.mainHeader}>
        <Typography
            variant={"body1"}
            color={"primary"}
            className={classes.title}
        >Műveletek
        </Typography>
        <div className={classes.icons}>
          {onCancel &&
              <Tooltip title={"mégsem"} placement="top" arrow>
                <IconButton color={"secondary"}
                            onClick={onCancel}>
                  <Cancel className={classes.largeIcon}/>
                </IconButton>
              </Tooltip>
          }
          <Tooltip title={"mentés"} placement="top" arrow>
            <IconButton color={"secondary"}
                        onClick={onSave}>
              <Save className={classes.largeIcon}/>
            </IconButton>
          </Tooltip>
          <Tooltip title={"törlés"} placement="top" arrow>
            <IconButton color={"secondary"}
                        onClick={onDelete}
                        disabled={!onDelete}>
              <DeleteForever className={classes.largeIcon}/>
            </IconButton>
          </Tooltip>
        </div>
      </div>
  )
}
export default Header3;

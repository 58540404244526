import {configureStore} from '@reduxjs/toolkit';
import reports from "./slices/reports";
import damageTypes from "./slices/damageTypes";
import topics from "./slices/topics";
import user from "./slices/user";
import error from "./slices/error";

export const store = configureStore({
  reducer: {
    reports: reports,
    damageTypes: damageTypes,
    topics: topics,
    user: user,
    error: error,
  },
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
});

import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    minWidth: 120,
    backgroundColor: 'rgb(193, 0, 31)',
    color: '#fff',
  }
}));

const CustomAlertDialog = ({
  open, title, message, handleAccept, acceptButtonText,
  handleDeny, denyButtonText
}) => {

  const classes = useStyles();
  const buttonAccept = (
      <Button
          className={classes.button}
          onClick={handleAccept}
      >
        {acceptButtonText ? acceptButtonText : 'rendben'}
      </Button>
  );
  const buttonDeny = (
      <Button
          className={classes.button}
          onClick={handleDeny}
      >
        {denyButtonText ? denyButtonText : 'mégsem'}
      </Button>
  );
  return (
      <Dialog
          open={open !== null}
          fullWidth
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {buttonAccept}
          {handleDeny ? buttonDeny : null}
        </DialogActions>
      </Dialog>
  );
}

export default CustomAlertDialog;

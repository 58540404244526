import {useEffect, useState} from "react";
import {
  div,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import axios from "axios";
import Header3 from "../headers/Header3";
import {
  fieldIsNullOrEmpty,
  mandatoryFieldHelperText
} from "../../utils/commonUtils";

const useStyles = makeStyles((theme) => ({

  container: {},
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    height: "100%",
  },
  textField: {
    width: "95%",
  },
  tableHeader: {
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(1),
    }
  }
}));

const DamageType = ({
  edit,
  damageTypeToEdit,
  save,
  cancel,
  deleteDamageType,
  errorMessage
}) => {
  const damageTypeBaseUrl = "/admin/damage-type/";
  const classes = useStyles();

  const [damageType, setDamageType] = useState(damageTypeToEdit);
  useEffect(() => {
    setDamageType(damageTypeToEdit)
  }, [damageTypeToEdit])

  const handleChange = (event, fieldName) => {
    const value = event.target.value;
    setDamageType({...damageType, [fieldName]: value});
  };
  const onSave = (damageType) => {
    save(damageType)
  }
  const onCancel = () => {
    cancel()
  }
  const onDelete = () => {
    deleteDamageType()
  }
  const onError = (error) => {
    errorMessage(error)
  }

  const handleSave = () => {
    if (fieldIsNullOrEmpty(damageType.name) || fieldIsNullOrEmpty(
        damageType.category) || fieldIsNullOrEmpty(damageType.description)) {
      onError("Hiányzó adat")
      return;
    }
    if (damageType.id === null || damageType.id === undefined) {
      axios
      .post(damageTypeBaseUrl, damageType)
      .then(res => onSave(res.data))
    } else {
      axios
      .put(damageTypeBaseUrl + damageType.id, damageType)
      .then(res => onSave(res.data))
    }
  };

  const handleDelete = () => {
    axios
    .delete(damageTypeBaseUrl + damageType.id)
    .then(() => onDelete(damageType.id))
  };

  return (
      edit && damageType && <div>
        <Paper className={classes.paper} elevation={0}>
          <Header3
              onCancel={onCancel}
              onSave={handleSave}
              onDelete={handleDelete}
          />
          <Grid container>
            <Grid item sm={3} xs={12} style={{alignItems: "baseline"}}>
              <Typography variant={"subtitle2"}
                          className={classes.tableHeader}>Név</Typography>
              <TextField
                  className={classes.textField}
                  required={true}
                  error={fieldIsNullOrEmpty(damageType.name)}
                  helperText={mandatoryFieldHelperText(damageType.name)}
                  variant="standard"
                  value={damageType.name}
                  placeholder={"Név"}
                  onChange={event => handleChange(event, "name")}
                  multiline={true}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <Typography variant={"subtitle2"}
                          className={classes.tableHeader}>Kategória</Typography>
              <TextField
                  className={classes.textField}
                  required={true}
                  error={fieldIsNullOrEmpty(damageType.category)}
                  helperText={mandatoryFieldHelperText(damageType.category)}
                  variant="standard"
                  value={damageType.category}
                  placeholder={"Kategória"}
                  onChange={event => handleChange(event, "category")}
                  multiline={true}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant={"subtitle2"}
                          className={classes.tableHeader}>Részletes leírás</Typography>
              <TextField
                  className={classes.textField}
                  required={true}
                  error={fieldIsNullOrEmpty(damageType.description)}
                  helperText={mandatoryFieldHelperText(damageType.description)}
                  variant="standard"
                  size={"medium"}
                  value={damageType.description}
                  placeholder={"Részletes leírás"}
                  onChange={event => handleChange(event, "description")}
                  multiline={true}
              />
            </Grid>
          </Grid>
        </Paper>
      </div>
  )
}

export default DamageType;

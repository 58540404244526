import React, {useEffect, useState} from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  makeStyles
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#555",
  },
  label: {
    '&.Mui-disabled': {
      color: "#555",
    },
  },
  row: {
      flexWrap: "nowrap"
  },
  wrappedRow: {
      flexWrap: "wrap"
  }
}));

const CheckBoxGroup = ({
  allItem,
  initiallyCheckedItems,
  checkedItems,
  disabled,
  wrapped
}) => {
  const classes = useStyles();
  const [checkBoxes, setCheckBoxes] = useState([]);

  useEffect(() => {
    const checkBoxGroup =
        allItem
        .map(item => {
          if (initiallyCheckedItems.includes(item)) {
            return {name: item, checked: true}
          } else {
            return {name: item, checked: false}
          }
        })
    setCheckBoxes(checkBoxGroup);
  }, []);

  const filterChecked = (event, checked) => {
    const checkBoxList = [...checkBoxes];
    checkBoxList.forEach((i) => {
      if (i.name === event.target.value) {
        i.checked = checked;
      }
    });
    checkedItems(
        checkBoxList.filter(item => item.checked).map(item => item.name));
    setCheckBoxes(checkBoxList);
  };

  return (
      <div>
        <FormGroup row className={wrapped ? classes.wrappedRow : classes.row}>
          {checkBoxes.map((checkBox) => (
              <FormControlLabel
                  key={checkBox.name}
                  classes={{
                    root: classes.root,
                    label: classes.label,
                  }}
                  control={
                    <Checkbox
                        disabled={disabled}
                        size={"small"}
                        value={checkBox.name}
                        checked={checkBox.checked}
                        onChange={filterChecked}
                    />
                  }
                  // label={<span
                  //     style={{fontSize: '0.9rem'}}>{checkBox.name}</span>}
                  label={checkBox.name}
              />
          ))}
        </FormGroup>
      </div>
  );
}

export default CheckBoxGroup;

import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
  div,
  Grid,
  makeStyles,
  Paper,
  Switch,
  TextField,
  Typography
} from "@material-ui/core";
import {AddCircle} from "@material-ui/icons";
import {Rating} from "@material-ui/lab";
import Review from "./Review";
import Section from "../Section";
import axios from "axios";
import LoadingScreen from "../LoadingScreen";
import Header1 from "../headers/Header1";
import {CustomSnackBar} from "../CustomSnackBar";
import CustomAlertDialog from "../CustomAlertDialog";
import {
  fieldIsNullOrEmpty,
  logError,
  mandatoryFieldHelperText
} from "../../utils/commonUtils";
import {useSelector} from "react-redux";
import {selectError} from "../../redux/slices/error";
import ErrorScreen from "../ErrorScreen";
import {constants} from "../../constants/constants";
import emptyImage from './emptyImage.png'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    height: "100%",
  },
  container: {
    padding: theme.spacing(1),
    color: "#555",
  },
  section: {
    marginBottom: theme.spacing(2),
    display: "flex",
    direction: "row",
    height: "40%",
  },
  coverContainer: {
    display: "flex",
    // justifyContent: "center",
  },
  cover: {
    maxHeight: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  coverIconLabel: {
    display: "inline-block",
    position: "relative",
  },
  coverIcon: {
    position: "absolute",
    top: -24,
    right: -24,
    color: theme.palette.secondary.main,
    fontSize: 36,
  },
  mainContainer: {},
  textField: {
    width: "90%",
  },
  switch: {},
}));

const Topic = () => {
  const classes = useStyles();
  const {id} = useParams();
  const topicByIdUrlBase = '/admin/topic/';
  const reviewsUrlBase = '/admin/review/';
  const filesUrl = constants.baseUrl + "/files/"
  const [topic, setTopic] = useState({});
  const [cover, setCover] = useState("");
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const navigation = useNavigate();
  const error = useSelector(selectError);

  useEffect(() => {
    if (id === "new") {
      // initialize topic as inactive. manager will activate it
      setTopic({active: false});
      setCover(emptyImage)
      setLoading(false)
    } else {
      getTopic();
    }
  }, []);

  const getTopic = () => {
    axios.get("/admin/topic/" + id)
    .then(res => {
      let topic = res.data;
      setTopic(topic);
      setCover(filesUrl + topic?.coverName)
      getReviewsByTopic()
    })
    .catch(err => logError(err))
  }

  const getReviewsByTopic = () => {
    axios
    .get("/admin/review/topic/" + id)
    .then(res => {
      setReviews(res.data);
      setLoading(false)
    })
    .catch(err => logError(err))
  }

  const [message, setMessage] = useState(null);

  const mandatoryFieldsMissing = () => {
    return fieldIsNullOrEmpty(topic.title) ||
        fieldIsNullOrEmpty(topic.summary) ||
        fieldIsNullOrEmpty(topic.description) ||
        fieldIsNullOrEmpty(topic.coverName)
  }

  const save = () => {
    if (mandatoryFieldsMissing()) {
      setMessage("Hiányzó adat!")
      return;
    }
    const formData = new FormData();
    formData.append('topic',
        new Blob([JSON.stringify(topic)], {
          type: 'application/json'
        }));
    formData.append("cover", topic.cover);
    axios
    .post(topicByIdUrlBase, formData)
    .then(() => navigation("/topics"))
    .catch(err => logError(err))
  };

  const update = () => {
    if (mandatoryFieldsMissing()) {
      setMessage("Hiányzó adat!")
      return;
    }
    const formData = new FormData();
    formData.append('topic',
        new Blob([JSON.stringify(topic)], {
          type: 'application/json'
        }));
    if (topic.cover !== undefined) {
      formData.append("cover", topic.cover);
    }
    axios
    .put(topicByIdUrlBase + id, formData)
    .then(() => navigation("/topics"))
    .catch(err => logError(err))
  }

  const updateReview = (review) => {
    axios
    .put(reviewsUrlBase + review.id, review)
    .then(res => {
      console.log(res.data);
    })
    .catch(err => logError(err))
  }

  const deleteReview = (review) => {
    setLoading(true)
    axios
    .delete(reviewsUrlBase + review.id, review)
    .then(getTopic)
    .catch(err => logError(err))
    .finally(() => setLoading(false))
  }

  const handleChange = (event, fieldName) => {
    const value = event.target.value;
    setTopic({...topic, [fieldName]: value});
  };
  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setTopic({...topic, cover: img, coverName: "placeHolder"});
      setCover(URL.createObjectURL(img));
    }
  }

  const handleDisabledChange = (event) => {
    const checked = event.target.checked;
    const updatedTopic = {...topic, active: checked};
    setTopic(updatedTopic);
  };

  if (error) {
    return <ErrorScreen/>
  }
  return loading ? <LoadingScreen/> : (
      <div>
        <Paper className={classes.paper} elevation={0}>
          <Header1
              edit={edit}
              onDone={() => !edit ? {} : setEdit(!edit)}
              onEdit={() => edit ? {} : setEdit(!edit)}
              onSave={() => edit ? {} : (id === "new" ? save()
                  : update())}
          />
          <Grid container>
            <Grid item xs={12} sm={12}>
              <div className={classes.container}>
                <div className={classes.section}>
                  <Section
                      icon={"visibility_off"}
                      iconText="Aktív"
                      content={
                        <div className={classes.switch}>
                          <Switch
                              inputProps={{'aria-label': 'controlled'}}
                              checked={topic.active}
                              aria-label={"Aktív"}
                              onChange={event => handleDisabledChange(event)}
                              disabled={!edit}
                          />
                        </div>
                      }/>
                </div>
                <div className={classes.section}>
                  <Section
                      icon={"collections"}
                      iconText="Borító"
                      content={
                        <div className={classes.coverContainer}>
                          <img
                              className={classes.cover}
                              src={cover}
                              alt="cover"/>
                          {edit &&
                              <label className={classes.coverIconLabel}
                                     onChange={handleImageChange}>
                                <input name="" type="file" hidden/>
                                <AddCircle className={classes.coverIcon}/>
                              </label>
                          }
                        </div>
                      }
                  />
                </div>
                <div className={classes.section}>
                  <Section
                      icon={"title"}
                      iconText="Cím"
                      content={edit ?
                          <TextField
                              className={classes.textField}
                              required={true}
                              error={fieldIsNullOrEmpty(topic.title)}
                              helperText={mandatoryFieldHelperText(topic.title)}
                              value={topic.title}
                              placeholder={"Cím"}
                              onChange={event => handleChange(event, "title")}
                              multiline={true}
                          />
                          :
                          <Typography style={{whiteSpace: 'pre-line'}}
                                      variant="body1">{topic.title}</Typography>
                      }
                  />
                </div>
                <div className={classes.section}>
                  <Section
                      icon={"subject"}
                      iconText="Rövid leírás"
                      content={edit ?
                          <TextField
                              className={classes.textField}
                              required={true}
                              error={fieldIsNullOrEmpty(topic.summary)}
                              helperText={mandatoryFieldHelperText(
                                  topic.summary)}
                              size={"medium"}
                              value={topic.summary}
                              placeholder={"Rövid leírás"}
                              onChange={event => handleChange(event, "summary")}
                              multiline={true}
                          />
                          :
                          <Typography style={{whiteSpace: 'pre-line'}}
                                      variant="body1">{topic.summary}</Typography>
                      }/>
                </div>
                <div className={classes.section}>
                  <Section
                      icon={"description"}
                      iconText="Részletes leírás"
                      content={edit ?
                          <TextField
                              className={classes.textField}
                              required={true}
                              error={fieldIsNullOrEmpty(topic.description)}
                              helperText={mandatoryFieldHelperText(
                                  topic.description)}
                              size={"medium"}
                              value={topic.description}
                              placeholder={"Részletes leírás"}
                              onChange={event => handleChange(event,
                                  "description")}
                              multiline={true}
                          />
                          :
                          <Typography style={{whiteSpace: 'pre-line'}}
                                      variant="body1">{topic.description}</Typography>
                      }/>
                </div>
                <div className={classes.section}>
                  <Section
                      icon={"star_rate"}
                      iconText="Értékelés"
                      content={
                        <Rating
                            size="small"
                            precision={0.5}
                            readOnly
                            value={topic.rating}
                        />
                      }/>
                </div>
                <div className={classes.section}>
                  <Section
                      icon={"rate_review"}
                      iconText="Vélemények"
                      content={
                        reviews.map(comment =>
                            <Review
                                key={comment.id}
                                userReview={comment}
                                updateReview={updateReview}
                                deleteReview={deleteReview}
                            />)
                      }
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </Paper>
        <CustomSnackBar open={edit}/>
        <CustomAlertDialog
            open={message}
            title={"Hiba"}
            message={message}
            handleAccept={() => setMessage(null)}
        />
      </div>
  );
};

export default Topic;

import {div, Grid, makeStyles, TextField, Typography} from "@material-ui/core";
import {useState} from "react";
import Section from "../Section";
import {Rating} from "@material-ui/lab";
import {formatDate} from "../../utils/DateUtils";
import Header1 from "../headers/Header1";
import {CustomSnackBar} from "../CustomSnackBar";
import User from "../users/User";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: theme.spacing(2),
    backgroundColor: "#f3f3f3",
    borderRadius: theme.spacing(1),
    margin: theme.spacing(1),
  },
  mainHeader: {
    display: "flex",
    paddingBottom: theme.spacing(2),
    justifyContent: "space-between",
    border: "solid",
  },
  title: {
    color: "#555",
    fontWeight: "bold",
  },
  icons: {
    display: "flex",
    width: "5%",
    [theme.breakpoints.down("sm")]: {
      width: "30%",
    },
    justifyContent: "space-between",
    paddingRight: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: 18,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
}));

const Review = ({userReview, updateReview, deleteReview}) => {
  const classes = useStyles();
  const [review, setReview] = useState(userReview);
  const [edit, setEdit] = useState(false);
  const handleReviewChange = (event) => {
    const reviewText = event.target.value;
    setReview({...review, review: reviewText});
  };
  const handleUpdate = (review) => {
    updateReview(review)
  }
  const handleDelete = (review) => {
    deleteReview(review)
  }

  const user = () => {
    if (!review.userId) {
      return <Typography>törölt felhasználó</Typography>;
    }
    if (!edit) {
      return <Typography>{review.userName}</Typography>;
    }
    return <User userId={userReview.userId}
                 save={() => setEdit(false)}
                 deleteUser={() => setEdit(false)}
                 extended={false}
    />
  };

  return (
      <div className={classes.mainContainer}>
        <Header1
            edit={edit}
            onEdit={() => edit ? {} : setEdit(!edit)}
            onDone={() => !edit ? {} : setEdit(!edit)}
            onSave={() => edit ? {} : handleUpdate(review)}
            onDelete={() => edit ? {} : handleDelete(review)}
        />
        <Grid container>
          <Section
              icon={"star_rate"}
              iconText="Értékelés"
              content={
                <Rating
                    size="small"
                    precision={0.5}
                    readOnly
                    value={review.rating}
                />
              }
          />
          <Section
              icon={"rate_review"}
              iconText="Vélemény"
              content={edit && edit ?
                  <TextField
                      variant="standard"
                      value={review.review}
                      onChange={handleReviewChange}
                      multiline={true}
                      style={{width: "100%"}}
                  />
                  :
                  <Typography style={{whiteSpace: 'pre-line'}}
                      variant="body1">{review.review}</Typography>
              }/>
          <Section
              icon={"calendar_today"}
              iconText="Dátum"
              content={<Typography>{formatDate(review.createdAt)}</Typography>}
          />
          <Section
              icon={"person"}
              iconText="Felhasználó"
              content={user()}
          />
        </Grid>
        <CustomSnackBar open={edit}/>
      </div>
  );
}

export default Review;

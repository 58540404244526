import {Grid, makeStyles} from "@material-ui/core";
import SectionItem from "./SectionItem";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    alignItems: "flex-start",
  },
}));

const Section = ({icon, iconText, content}) => {
  const classes = useStyles();

  return <Grid container className={classes.mainContainer}>
    <Grid item xs={2} sm={2}>
      <SectionItem
          icon={icon}
          iconText={iconText}
      />
    </Grid>
    <Grid item xs={10} sm={10}>
      {content}
    </Grid>
  </Grid>

}

export default Section;

import {reportStatus} from "../constants/constants";

const roles =
    [
      {
        id: 0,
        name: "admin",
        translated: "admin"
      },
      {
        id: 1,
        name: "manager",
        translated: "menedzser"
      },
      {
        id: 2,
        name: "user",
        translated: "felhasználó"
      },
    ]

export const getTranslatedRole = (role) => {
  if (role === undefined || role === null) {
    return roles.find(item => item.name === "user").translated;
  }
  return roles.find(item => item.name === role).translated;
}

export const getRole = (role) => {
  if (role === undefined || role === null) {
    return roles.find(item => item.translated === "felhasználó").name;
  }
  return roles.find(item => item.translated === role).name;
}

export function resolveMarkerColor(report, draggable, theme) {
  if (draggable) {
    return theme.palette.secondary.main;
  }
  if (!report.active) {
    return "gray"
  }
  if (report.status === reportStatus[0]) {
    return theme.palette.success.main;
  }
  return theme.palette.primary.main;
}

export const fieldIsNullOrEmpty = (field) => {
  return field === null || field === undefined || field === "" || field === {};
}

export const mandatoryFieldHelperText = (field) => {
  return fieldIsNullOrEmpty(field) ? "kötelező mező" : "";
}

export const mapAuthCodeToMessage = authCode => {
  switch (authCode) {
    case "auth/invalid-password":
      return "Érvénytelen jelszó";
    case "auth/invalid-email":
      return "Érvénytelen email";
    case "auth/user-not-found":
      return "Ismeretlen felhasználó";
    case "auth/user-disabled":
      return "Tiltott felhasználó";
    case "auth/wrong-password":
      return "Hibás jelszó";
    case "auth/weak-password":
      return "A jelszónak legalább 6 karaktert kell tartalmaznia"
    case "auth/email-already-in-use":
      return "Az emailcím már használatban van"
    default:
      return "Váratlan hiba történt";
  }
};

export function logError(error) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
  }
  console.log(error.config);
}

import React, {useState} from 'react'
import {
  Avatar,
  Button,
  Grid,
  Link,
  makeStyles,
  Paper,
  TextField,
  Typography
} from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {setUser} from "../../redux/slices/user";
import Navbar from "../Navbar";
import CustomAlertDialog from "../CustomAlertDialog";
import {
  fieldIsNullOrEmpty,
  mandatoryFieldHelperText,
  mapAuthCodeToMessage
} from "../../utils/commonUtils";
import ErrorScreen from "../ErrorScreen";
import {selectError} from "../../redux/slices/error";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
    height: '70vh',
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    marginTop: theme.spacing(2),
    margin: "auto"
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
  },
  button: {
    marginTop: theme.spacing(2),
  },
  text: {
    marginTop: theme.spacing(2),
  },
  textField: {
    height: 60,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }
}));

const Login = () => {

  const classes = useStyles();
  const auth = getAuth();
  const navigation = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const userBaseUrl = "/admin/user";
  const dispatch = useDispatch();
  const error = useSelector(selectError);

  const handleEmailChange = (event) => {
    const email = event.target.value;
    setEmail(email);
  };
  const handlePasswordChange = (event) => {
    const password = event.target.value;
    setPassword(password);
  };

  const [message, setMessage] = useState(null);

  const handleLogin = () => {
    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      user.getIdToken(true)
      .then(() => axios.get(userBaseUrl + "/me"))
      .then(res => dispatch(setUser(res.data)))
      .then(() => navigation("/"));
    })
    .catch((error) => {
      auth.signOut().then(() => {
        const errorCode = error.code;
        setMessage(mapAuthCodeToMessage(errorCode));
      });
    });
  };

  if (error) {
    auth.signOut().then(() => console.log(error));
    return <ErrorScreen/>
  }
  return (
      <Grid>
        <Navbar/>
        <Paper elevation={0} className={classes.paper}>
          <Grid align='center'>
            <Avatar className={classes.avatar}><LockOutlinedIcon/></Avatar>
          </Grid>
          <TextField
              value={email}
              onChange={handleEmailChange}
              label='Email'
              fullWidth
              required
              error={fieldIsNullOrEmpty(email)}
              helperText={mandatoryFieldHelperText(email)}
              className={classes.textField}
          />
          <TextField
              value={password}
              onChange={handlePasswordChange}
              label='Jelszó'
              type='password'
              fullWidth
              required
              error={fieldIsNullOrEmpty(password)}
              helperText={mandatoryFieldHelperText(password)}
              className={classes.textField}
          />
          <Button
              onClick={handleLogin}
              type='submit'
              color='primary'
              variant="contained"
              className={classes.button}
              fullWidth>
            Belép
          </Button>
          <Typography className={classes.text}>
            <Link href="/register">
              Regisztráció
            </Link>
          </Typography>
        </Paper>
        <CustomAlertDialog
            open={message}
            title={"Hiba"}
            message={message}
            handleAccept={() => setMessage(null)}
        />
      </Grid>
  )
}

export default Login;

import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  topics: [],
};

const topics = createSlice({
  name: 'topics',
  initialState,
  reducers: {
    setTopics: (state, action) => {
      state.topics = action.payload;
    },
  },
});

export const {setTopics} = topics.actions;

export const selectTopics = state => state.topics.topics;

export default topics.reducer;
